@font-face {
  font-family: "Nanum";
  font-weight: 700;
  font-style: normal;
  src: url("./NanumGothicExtraBold.otf") format("otf"),
    url("./NanumGothicExtraBold.ttf") format("ttf");
}

@font-face {
  font-family: "Nanum";
  font-weight: 700;
  font-style: normal;
  src: url("./NanumGothicBold.otf") format("otf"),
    url("./NanumGothicExtraBold.ttf") format("ttf");
}

@font-face {
  font-family: "Nanum";
  font-weight: 400;
  font-style: normal;
  src: url("./NanumGothic.otf") format("otf"),
    url("./NanumGothic.ttf") format("ttf");
}
