@font-face {
  font-family: "Apple";
  font-weight: 700;
  font-style: normal;
  src: url("./AppleSDGothicNeoB.ttf") format("ttf");
}

@font-face {
  font-family: "Apple";
  font-weight: 600;
  font-style: normal;
  src: url("./AppleSDGothicNeoSB.ttf") format("ttf");
}

@font-face {
  font-family: "Apple";
  font-weight: 500;
  font-style: normal;
  src: url("./AppleSDGothicNeoM.ttf") format("ttf");
}

@font-face {
  font-family: "Apple";
  font-weight: 400;
  font-style: normal;
  src: url("./AppleSDGothicNeoR.ttf") format("ttf");
}

@font-face {
  font-family: "Apple";
  font-weight: 300;
  font-style: normal;
  src: url("./AppleSDGothicNeoL.ttf") format("ttf");
}
