@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .flex-ic {
    @apply flex items-center;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .carouselAnimation {
    @apply transition-all duration-700 ease-in-out;
  }

  .put-top-center {
    @apply top-2/4 -translate-y-2/4;
  }

  .introduceCarouselAnimation {
    @apply transition-all duration-[1.5s] ease-in;
  }
}

html {
  font-size: 62.5% !important;
}

.maingBgGradient {
  background: linear-gradient(
    90.15deg,
    rgba(0, 0, 0, 0.37) 0.13%,
    rgba(217, 217, 217, 0) 100.08%
  );
}
