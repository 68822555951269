@font-face {
  font-family: "Noto";
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src: url("./NotoSansKR-Black.otf") format("otf");
}

@font-face {
  font-family: "Noto";
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url("./NotoSansKR-Bold.otf") format("otf");
}

@font-face {
  font-family: "Noto";
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url("./NotoSansKR-Medium.otf") format("otf");
}

@font-face {
  font-family: "Noto";
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url("./NotoSansKR-Regular.otf") format("otf");
}

@font-face {
  font-family: "Noto";
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url("./NotoSansKR-Light.otf") format("otf");
}

@font-face {
  font-family: "Noto";
  font-weight: 200;
  font-display: swap;
  font-style: normal;
  src: url("./NotoSansKR-Thin.otf") format("otf");
}
